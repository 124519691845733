.header {
  position: absolute;
  background-color: rgba(255,255,255, 0.5);

  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 120px;
  top: 0px;
  z-index: 1000;
  // box-shadow: 2px 1px 8px 0 rgba(0, 0, 0, 0.2);
  @media (max-width: 1500px) {
    padding: 0 8rem;
  }
  @media (max-width: 500px) {
    padding: 0 1rem;
  }
  @media (max-width: 1050px) {
    justify-content: space-between;
  }

  @media (max-width: 1280px) {
    padding: 0 2rem;
  }

  @media (max-width: 1050px) {
    padding: 0 1rem;
  }
  
  .right-item {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .contact-info {
      @media (max-width: 320px) {
        display: none;
      }
    }

    @media (min-width: 1051px) {
      width: 15%;
      display: none;
    }
  }

  .menu-mobile-button {
    cursor: pointer;
    @media (min-width: 1050px) {
      display: none;
    }
    z-index: 2000;
  }

  .logo-header {
    @media (min-width: 1051px) {
      width: 15%;
    }
    .logo {
      display: flex;
      width: 250px;

      @media (max-width: 450px) {
        width: 200px;
      }
    }
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #e3e3e3;
    z-index: 1000;
    color: #888c8c;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100vw;

    &.active {
      height: 70vh;
    }
    &.active > .mobile-menu__menu-items {
      display: initial;
    }

    &__menu-items {
      list-style: none;
      display: none;

      &__item {
        padding: 10px 30px;
        position: relative;
        cursor: pointer;
        font: inherit;

        @media (max-width: 1165px) {
          padding: 10px 1rem;
        }

        &:hover {
          color: #00ffb4;
          transition: 1s;
        }

        &__sub-menu {
          padding-left: 1rem;
          list-style-type: none;

          &__item {
            color: #00ffb4;
            &:hover {
              color: white;
            }
          }
        }
      }
    }
  }

  .menu-items {
    display: flex;
    justify-content: center;
    list-style: none;
    color: black; // #cdcdcd

    @media (max-width: 1500px) {
      margin-right: 1rem;
    }
    @media (max-width: 1050px) {
      display: none;
    }

    &__item {
      padding: 10px 50px; //10px 30px
      text-transform: uppercase;
      position: relative;
      cursor: pointer;
      font: inherit;

      @media (max-width: 1165px) {
        padding: 10px 1rem;
      }

      &:hover {
        color: #cdcdcd; //#00ffb4
        transition: 1s;
      }

      &:hover > .menu-items__item__sub-menu {
        visibility: initial;
        opacity: 1;
        z-index: 1000;
        top: 4rem;
      }

      &__sub-menu {
        position: absolute;
        transition: 2s;
        visibility: hidden;
        opacity: 0;
        z-index: -100;
        list-style-type: none;
        top: 0;
        padding: 1rem 2rem;
        background: #555555;

        &__item {
          color: white;
          &:hover {
            color: #00ffb4;
          }
        }
      }
    }
  }

  .language {

    position: relative;
    display: flex;
    justify-items: center;
    justify-content: space-between;
    align-items: center;
    width: 70px;
    padding: 5px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;

      &.is-open {
        opacity: 1;
      }
    }

    .language-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #e3e3e3;
      display: flex;
      flex-direction: column;

      .language-option {

      }
    }
  }
}
