.your-talents {
  $side-padding: 110px;
  $text-color: #3d3935;

  .content-container {
    padding: 7vw;
    padding-bottom: 0;

    @media (max-width: 640px) {
      margin-top: 30px;
    }

    .title {
      text-align: center;
      font-size: 1.8em;
      line-height: 59px;
      font-size: 40px;
      color: $text-color;
      margin: 0 auto;
      margin-bottom: 40px;

      @media (max-width: 640px) {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 20px;
      }
    }

    .subtitle {
      text-align: center;
      font-size: 16px;
      color: $text-color;
      line-height: 36px;
      margin: 10px auto 0;
      max-width: 500px;

      @media (max-width: 640px) {
        font-size: 12px;
        line-height: 21px;
        max-width: unset;
        margin-bottom: 20px;
      }
    }
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .hex {
      $hex-side: 220px;

      width: $hex-side;
      height: $hex-side;
      position: relative;

      .hex-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% + 7px));
        text-align: center;
        text-transform: uppercase;
        color: #3d3935;
        font-size: 15px;
        line-height: 24px;

        @media (max-width: 700px) {
          word-break: break-word;
          width: 80%;
          font-size: 0.8em;
        }

        @media (max-width: 500px) {
          font-size: 0.6em;
        }
      }

      svg {
        width: 100%;
        height: 100%;
      }
      @media (max-width: 700px) {
        width: 32vw;
        height: 32vw;
      }
    }

    @media (min-width: 1300px) {
      padding: 0 5vw;
    }
  }
}
