.nav {
	position: relative;
	height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	padding: 0px 300px;
	top: 0px;
	z-index: 1000;
	box-shadow: 2px 1px 4px 0 rgba(0, 0, 0, 0.2);
	.nav-items {
		display: contents;
		justify-content: center;
		list-style: none;
		color: black; // #cdcdcd
		&__item {
			padding: 10px 50px; //10px 30px
			text-transform: uppercase;
			position: relative;
			font: inherit;
			text-decoration: underline;
			text-transform: uppercase;
		}
	}
}
