.home {
  &__slider {
    position: relative;

    background-position-y: bottom;
    background-position-x: left;
    background-size: cover;

    & > .location-slider {
      @media (max-width: 980px) {
        display: none !important;
      }
    }
    & > .social-slider {
      @media (max-width: 980px) {
        right: 1rem;
      }
    }
  }

  &__our-differences {
    position: relative;
  }
  &__our-allies {
    position: relative;
  }
  &__clients-comments {
    position: relative;
    background-color: #181841;
  }
  &__what-you-need {
    position: relative;
  }
  &__experts-opinions {
    position: relative;
    background-color: #eee;
  }
  &__contact-home {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      position: absolute;
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(0, 0, 0, 0.2)),
        to(rgba(0, 0, 0, 0.5))
      );
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
    }
  }
}
