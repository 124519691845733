.slider {
	$text-color: #3d3935;

	height: 100vh;
	background-color: #8bb8be;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 7.5%;
	padding-top: 200px;
	position: relative;
	z-index: 1;

	font-family: 'Montserrat';

	@media (max-width: 980px) {
		height: 100vh;
	}
	@media (max-width: 980px) {
		padding: 7%;
	}

	@media (min-width: 1280px) {
		padding: 7.5% 11rem;
	}

	&.background-image {
		background-position: right;
		background-size: cover;
		background-repeat: no-repeat;

		height: 110vh;
		max-height: 1000px;

		@media (max-width: 900px) {
			background-position: bottom;
			height: 90vh;
		}
		@media (max-width: 900px) and (orientation: landscape) {
			background-position: bottom;
			height: 198vh;
		}
		@media (max-width: 360px) {
			background-position: bottom;
			height: 110vh;
		}

		&.home-page {
			height: 120vh;
			min-height: 1000px;
			max-height: 1200px;

			@media (max-width: 900px) {
				background-position: bottom;
				min-height: 800px;
				height: 100vh;
			}
		}

		&.for-careers {
			background-position: 48% 38%;
			background-size: unset;

			@media (max-width: 1279px) {
				background-position: 38% 38%;
			}
			@media (max-width: 575px) {
				background-position: 42% 38%;
			}
		}

		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 80%;
			height: 100%;
		}
	}

	&.for-investors {
		background-color: transparent;
		.background-container {
			content: '';
			position: absolute;
			width: 100%;
			left: 0;
			height: 1000px;
			z-index: -1;
			top: 0;
		}
	}

	&.for-business {
		z-index: 999;
		background-color: transparent;
	}

	&__title {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 47%;
		text-align: start;

		@media (max-width: 1279px) {
			width: 80%;
		}
		@media (max-width: 980px) {
			width: 100%;
		}
		@media (min-width: 1280px) {
			width: 75%;
		}

		&__page-indicator {
			background-color: #00ffb4;
			color: #181841;
			padding: 0.5rem 1.5rem;
			border-radius: 100px;
			font-size: 0.8rem;
			font-weight: 700;
			letter-spacing: 2px;
			font-family: 'Montserrat';
			text-transform: uppercase;

			@media (max-width: 1499px) {
				display: none;
			}
		}
		&__title {
			color: $text-color;
			line-height: 1.2;
			text-align: start;
			font-family: 'Sinkin Sans', sans-serif;
			font-weight: 200;
			margin-bottom: 24px;
			@media (max-width: 639px) {
				margin-bottom: -10px;
			}

			h1 {
				@media (max-width: 639px) {
					font-size: 34px;
					line-height: 42px;
				}
			}
		}

		&__description {
			width: 70%;
			color: $text-color;
			text-align: start;
			margin-top: 1rem;
			margin-bottom: 1rem;
			p {
				font-size: 1.5rem;
				line-height: 34px;
				color: $text-color;
				font-family: 'Roboto', sans-serif;

				@media (max-width: 639px) {
					font-size: 18px;
					line-height: 26px;
				}
			}

			@media (max-width: 980px) {
				width: 100%;
			}
		}
	}

	&__number {
		position: absolute;
		bottom: 2rem;
		left: 4rem;
		.container {
			display: flex;
			flex-direction: row;
			align-items: center;

			.img {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 20px;
			}

			.text {
				color: #cdcdcd;
				line-height: 1;
				letter-spacing: 2px;
				font-size: 0.8rem;
				.number {
					font-size: 1rem;
					letter-spacing: normal;
					color: white;
					display: block;
					line-height: inherit;
					letter-spacing: 1px;
					color: white;
					display: block;
					line-height: 1;
					&:hover {
						color: #00ffb4;
					}
				}
			}
		}
	}
}
