.when-clear-goal {
    padding: 5vw;
    background-size: 54%;
    background-position: 0% 90%;
    position: relative;

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 150%;
        width: 100%;
        z-index: -1;
        background-image: linear-gradient(#b4dfe5 10%, white 90%, #fff 140%);
    }
    
    padding-top: 134%;
    background-size: 120%;
    background-position: 0% 0%;

    @media (min-width: 641px) {
        padding-top: 529px;
        background-size: 800px;
        background-position: 0% -300px;
    }

    @media (min-width: 980px) {
        background-size: 800px;
        background-position: 0% -300px;
        padding-top: 149px;
    }

    @media (min-width: 1300px) {
        padding: 5vw;
    }

    .content-container {
        padding-left: 50%;
        width: 100%;

        .title {
            font-size: 24px;
            z-index: 9990;
            color: #3d3935;
            line-height: 32px;
            
            @media (max-width: 640px) {
                margin-bottom: 30px;
            }

            @media (max-width: 980px) {
                line-height: 34px;
            }
        }

        .description {
            font-size: 21px;
            white-space: pre-line;
            font-family: 'Roboto';
            line-height: 34px;
            margin-top: 15px;
            color: #4d4d4d;
            @media (max-width: 640px) {
                font-size: 16px;
                line-height: 22px;
            }
        }

        @media (max-width: 980px) {
            padding: 0 10px;
        }

    }
}