.bussiness {
  &__slider {
    position: relative;

    & > .location-slider {
      @media (max-width: 980px) {
        display: none !important;
      }
    }
    & > .social-slider {
      @media (max-width: 980px) {
        right: 1rem;
      }
    }
  }
  &__first-section {
    position: relative;
  }
}
