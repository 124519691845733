.footer {
	background-color: #f0f0f0;
	position: relative;
    z-index: 99999;

	.footer-content {
		display: flex;
		padding: 0rem 5rem;
		color: #555555;
		text-align: center;

		@media (max-width: 1000px) {
			flex-direction: column-reverse;
			padding: 1rem 1.5rem;
		}

		.menu-section {
			width: 100%;
			@media (max-width: 1000px) {
				width: 100%;
			}

			.top {
				margin-bottom: 0;

				&__text {
					max-width: 60%;
					@media (max-width: 1000px) {
						max-width: 100%;
					}
				}
			}

			.bottom {
				@media (max-width: 5000px) {
					width: 100%;
				}
				.menu {
					.head {
						display: flex;
						color: #555555;
						flex-wrap: wrap;
						// opacity: 0.7;
						padding: 2rem 0;
						align-items: center;
						
						a {
							text-transform: uppercase;
							font-size: 10px;
						}

						@media (max-width: 1000px) {
							padding: 1rem 0 0;
						}
					}

					.links {
						display: flex;
						padding: 2rem 0;
						opacity: 0.7;
						span {
							font-weight: bold;
							margin-bottom: 1rem;
						}
						ul {
							margin-top: 0.5rem;
						}
						li {
							font-weight: 400;
							margin-bottom: 1rem;
							&:hover {
								color: #00ffb4;
							}
						}

						.individuals {
							color: #555555;
							margin-right: 3rem;
							align-items: center;
						}
					}
				}
			}

			ul {
				list-style: none;
			}
		}

		.contact-section {
			margin: 0 auto;
			text-align: left;
			padding-left: 27px;

			@media (max-width: 1000px) {
				padding-left: 0;
			}

			.contact-item {

				display: flex;
				margin-bottom: 20px;

				.contact-text {
					font-size: 10px;
					width: 90%;
					max-width: 332px;
					margin-left: 20px;
					line-height: 15px;

					@media (max-width: 1000px) {
						font-weight: 500;
					}
				}
			}
		}

		.company-section {
			width: 20%;
			position: relative;
			display: flex;
			flex-direction: column;
			margin-right: 7%;
			@media (max-width: 1000px) {
				align-items: flex-start;
				width: 100%;
				margin-right: 0;
			}

			&__logo {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				@media (max-width: 1000px) {
					flex-direction: row;
					justify-content: space-between;
					margin-bottom: 1rem;
				}

				.footer-logo {
					// width: 200px;
					// margin-right: 8rem;
					width: 227px;
				}
			}

			.widget {
				position: initial;

				&.location-slider {
					width: 23rem;

					@media (max-width: 1000px) {
						width: 90%;
					}
				}
			}
		}

		.links-section {
			flex-wrap: wrap;
			justify-content: space-around;
			text-transform: uppercase;
			font-size: 12px;

			&.first {
				width: 60%;
				display: flex;
				flex-flow: row;

				@media (max-width: 1000px) {
					display: none;
				}
			}

			&.second {
				display: none;
				flex-flow: column;
				align-items: start;
				height: 207px;
				margin-top: 20px;

				@media (max-width: 1000px) {
					display: flex;
				}
			}

		}
	}

	.footer-final {

		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		padding: 20px 5rem 20px calc(5rem + 12px);
		

		.social-footer {
			align-items: center;
		}

		.footer-copyright {
			color: black;

			span {
				font-family: 'Roboto';
				font-size: 12px;
				margin-right: 20px;

				@media (max-width: 1000px) {
					font-weight: 400;
				}
			}
		}

		@media (max-width: 1000px) {
			padding: 1rem 1.5rem;
			padding-top: 0;
			width: 100%;
			padding-bottom: 40px;
		}
	}

}
