.culture-home {
  background-color: #f1c400;
  background-size: cover;
  background-origin: border-box;
  background-clip: padding-box;
  margin-top: -160px;

  position: relative;
  z-index: 2;
  padding: 50px 0 30px 0;

  display: flex;
  flex-direction: row;

  border-style: solid;
  border-color: rgba(#000, 0);
  border-width: 10rem 0 0 0;

  @media (min-width: 901px) {
    &:before {
      position: absolute;
      z-index: 1; /* put it *behind* parent */
      /* go outside padding-box by 
         * a border-width ($b) in every direction */
      top: -9.9rem;
      width: 100%;

      border: inherit;
      background: #f1c400;
      border-color: transparent;
      background-clip: border-box;

      -webkit-mask: -webkit-linear-gradient(transparent 10%, #f1c400);
      -webkit-mask: linear-gradient(transparent 10%, #f1c400);
      mask: url(../../assets/icons/blurs.svg#mask-linear-yellow);
      filter: url(../../assets/icons/blurs.svg#filter-2);

      content: "";
    }
  }

  @media (max-width: 900px) {
    margin-top: -100px;
    padding: 30px 0 3rem 0;
    border: none;
  }

  > div {
    width: 40%;
    margin: -10rem 0 0 5%;
    z-index: 2;
    padding-left: 5%;
    @media (max-width: 900px) {
      width: 100%;
      margin: 0;
      padding-left: 0;
    }
  }

  img {
    position: absolute;
    z-index: 100;
    top: calc(-30px - 10rem);
    width: 50%;
    max-width: 550px;
    right: 0;
    z-index: 2;
    @media (max-width: 900px) {
      display: none;
    }
  }

  &-title {
    @media (max-width: 900px) {
      width: 90%;
      margin: 0 0 0 7%;
    }

    h2 {
      font-family: "Sinkin Sans";
      font-weight: 500;
      color: #3d3935;
      font-size: 40px;
      line-height: 56px;
      width: 80%;
      @media (max-width: 1200px) {
        font-size: 32px;
        line-height: 37px;
      }
      @media (max-width: 900px) {
        font-size: 24px;
        line-height: 34px;
        font-weight: 600;
      }
    }
  }

  &-items {
    display: flex;
    flex-direction: column-reverse;
    margin: 1.5rem 0 0 0;
    gap: 1.5rem;
    @media (max-width: 900px) {
      width: 85%;
      margin: 1.5rem 5% 0 7%;
    }

    p {
      font-family: "Sinkin Sans";
      font-weight: 500;
      color: #3d3935;
      @media (max-width: 900px) {
        font-size: 14px;
        line-height: 29px;
      }
    }
  }

  &-more {
    margin: 40px 0 60px 0;
    opacity: 1;
    cursor: pointer;
    transition: 0.3s;

    @media (max-width: 900px) {
      width: 85%;
      margin: 3rem 5% 0 7%;
      padding-bottom: 60px;
    }

    &:hover {
      opacity: 0.7;
    }
    p {
      font-family: "Sinkin Sans";
      font-weight: 700;
      color: #3d3935;
      text-transform: uppercase;
      @media (max-width: 900px) {
        font-size: 15px;
        line-height: 25px;
      }

      svg {
        margin: 0 0 0 5px;
        path {
          fill: #3d3935;
        }
      }
    }
  }
}
