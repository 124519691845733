.busines {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
}

.brands-navbar {
  border-top: 4px solid #3D3935;
  padding-top: 23px;
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  z-index: 9999999;
  position: relative;
  max-width: 1280px;

  @media (max-width: 1000px) {
    border-top: 2px solid #3D3935;
  }

  .brand-container {
    margin-bottom: 20px;
    
    .brand-name {
      cursor: pointer;
      font-size: 13px;
      text-transform: uppercase;
      text-decoration: underline;
      color: #3D3935;
    }
  }

  @media (max-width: 900px) {
    max-width: 1280px;
    flex-direction: column;
    text-align: center;
    width: 95%;
  }
}

.business {
  &__our-differences {
    position: relative;

    &.first {
      position: relative;
      .main-gradient-container {
        position: absolute;
        top: -86vh;
        width: 100%;
        height: 166vh;
        z-index: -1;
        background-image: linear-gradient(to top, #BFDDE0 1%, #BFDDE0 50%, transparent 100%);



        @media (min-height: 900px) {
          top: -98vh;
        }
        @media (min-width: 2000px) {
          top: -43vh;
          height: 108vh;
        }
        @media (min-height: 1200px) {
          // top: -64vh;
          // height: 133vh;
        }
      }
    }
  }
  &__slider {
    position: relative;

    & > .location-slider {
      @media (max-width: 980px) {
        display: none !important;
      }
    }
    & > .social-slider {
      @media (max-width: 980px) {
        right: 1rem;
      }
    }
  }

  .background-container {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: -1;
    top: 0;
    background-size: 1448px;
    background-repeat: no-repeat;
    // background-position: -549px 0%;
    height: 1500px;
    z-index: 1;
    background-position: -8px -67px;

    @media (min-width: 1441px) {
      background-position: 0 0;
      background-size: 100%;
    }
    @media (max-width: 900px) {
      background-size: 1800px;
      background-repeat: no-repeat;
      height: 1500px;
      background-position: -140px -33px;
    }
    // border: 5px solid red;

    // @media (max-width: 1091px) {
    //   background-position: -1549px 40%;
    // }
    // @media (min-width: 2050px) {
    //   background-position: -365px 40%;
    // }

    // &:before {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   height: 400px;
    //   width: 100%;
    //   // border: 5px solid blue;
    //   // background-image: linear-gradient(to bottom, transparent 20%, rgba(191, 221, 224, 0.6) 50%, #BFDDE0 100%);
    // }
  }

  &__our-differences {
    &.first {

      padding-top: 100px;
      background-image: linear-gradient(to bottom, transparent 1%,#BFDDE0 24%, #BFDDE0 100%);
      z-index: 2;
    }
  }
}
