.careers {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
}
.career {
  &__slider {
    position: relative;

    & > .location-slider {
      @media (max-width: 980px) {
        display: none !important;
      }
    }
    & > .social-slider {
      @media (max-width: 980px) {
        right: 1rem;
      }
    }
  }
}
