.information-sectionb2 {
	position: relative;
	width: 100%;
	background-color: #f0f0f0;

	.info-container {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		padding: 3rem 3rem;

		@media (max-width: 970px) {
			flex-direction: column;
			padding: 2rem 2rem;
		}

		@media (max-width: 1320px) {
			padding: 5rem 2.5rem;
		}

		&__title {
			width: 80%;
			white-space: pre-wrap;
			@media (max-width: 970px) {
				width: 100%;
			}

			h1.title {
				@media (max-width: 970px) {
					max-width: 100%;
				}
			}
		}
		&__text {
			width: 80%;
			white-space: pre-wrap;
			@media (max-width: 970px) {
				width: 100%;
			}

			h1.title {
				@media (max-width: 970px) {
					max-width: 100%;
				}
			}
		}

		&__text {
			padding: 2rem;
			display: flex;
			justify-content: center;
		}

		&__title {
			h1 {
				font-size: 2.5rem;
				line-height: 1.3;
				font-family: 'Montserrat';
				color: #262626;
				@media (max-width: 1250px) {
					font-size: 1.8rem;
				}
			}
			h2 {
				margin-top: 2rem;
				margin-bottom: 1.7rem;
				font-size: 1.7rem;
				font-family: 'Montserrat';
				color: #262626;
				text-align: start;
				line-height: 1.5;
				font-style: italic;
				@media (max-width: 1250px) {
					font-size: 1.2rem;
				}
			}
			p {
				font-size: 1.3rem;
				font-family: 'Montserrat';
				color: #262626;
				text-align: start;
				font-weight: 200;
				line-height: 1.5;
				@media (max-width: 1250px) {
					font-size: 1.2rem;
				}
			}
			@media (min-width: 891px) and (max-width: 1250px) {
				padding: 0 2rem;
			}
			@media (max-width: 970px) {
				padding: 0;
				margin-bottom: 1rem;
			}
			@media (max-width: 640px) {
				width: 70%;
			}

			padding: 0 1rem;
		}
	}
	.info-container__text {
		width: 80%;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 3rem 3rem;
	}
}
