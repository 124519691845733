@import "../scss/components/button.component.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&wght@600&wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import 'fonts';

$font-button: "Montserrat", sans-serif;
$font-titles: "Montserrat", serif;
$font-text: "Montserrat", sans-serif;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

body {
  box-sizing: border-box;

  &--wrapper {
    width: 100vw;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

a,
div,
p,
span {
  // font-size: 1.6rem;
  line-height: 2;
}

a {
  font-family: $font-button;
  font-weight: 600;

  &.nav-link {
    font-size: 14px;
  }
}

p,
div {
  font-family: $font-text;
}

h1 {
  font-family: $font-titles;
  font-weight: 300;
}

h2 {
  font-family: $font-titles;
  font-weight: 300;
}

h1.title {
  font-size: 3.5rem;
  line-height: 1.5;

  &.small {
    font-size: 2.5rem;

    @media (max-width: 980px) {
      font-size: 2rem;
      max-width: 380px;
    }
  }

  @media (max-width: 980px) {
    font-size: 2.5rem;
    margin-bottom: 15px;
    // max-width: 290px;
  }
}
h2.title {
  font-size: 2rem;
  line-height: 1.5;
  @media (max-width: 980px) {
    margin-bottom: 15px;
  }
}

.main-wrapper {
  position: relative;
  height: 100vh;
}

.widget {
  position: absolute;
  z-index: 100;
}

hr {
  border: 0.5px solid #cdcdcd;
}

.background-section {
    background-repeat: no-repeat;
    background-size: cover;
}

.font--normal { font-style: normal; }
.font--italic { font-style: italic; }
.font--initial { font-style: initial; }
.font--lighter { font-weight: lighter; }
