.contact-us-home {
  width: 100%;
  padding: 87px 0 0 5%;

  display: flex;
  flex-direction: row;
  gap: 5%;

  background-image: url(../../assets/images/home/contact-us-home-background.webp),
    url(../../assets/images/home/contact-us-home-background.webp);
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  background-position: 0 0px;
  justify-content: space-between;
  @media (max-width: 900px) {
    padding: 200px 0 0 0;
    gap: 0;
    flex-direction: column;
  }

  &-texts {
    width: 50%;
    padding: 4rem 0 0 0;
    @media (max-width: 900px) {
      padding: 0;
      width: 100%;
    }

    @media (min-width: 1280px) {
      padding: 7rem 0 0 0;
    }
    @media (min-width: 1600px) {
      padding: 10vw 0 0 0;
    }
  }

  &__title {
    font-size: 2em;
    width: 93%;
    line-height: 42px;
    font-family: "Sinkin Sans", sans-serif;
    font-weight: 700;
    color: #3d3935;

    font-size: 20px;
    line-height: 36px;

    @media (max-width: 900px) {
      width: 70%;
      margin: 0 auto 0 10%;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__subtitle {
    font-family: "Sinkin Sans", sans-serif;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: #3d3935;
    width: 100%;
    margin: 1rem 0 0 0;
    @media (max-width: 900px) {
      width: 80%;
      margin: 2rem auto 0 auto;
      font-size: 24px;
      line-height: 34px;
      font-weight: 600;
    }
  }

  .contact-form {
    position: relative;
    max-width: 720px;
    
    @media (min-width: 1600px) {
      padding: 8rem 0rem 8rem 10rem;
    }

    padding: 114px 3rem 5rem 7rem;
    width: 50%;

    @media (max-width: 1100px) {
      padding: 114px 0rem 5rem 7rem;
    }

    @media (max-width: 900px) {
      background: url(../../assets/icons/home/BACKGROUNDS/contact-us-home-form-background-mobile.svg);
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      padding: 5rem 10% 2rem 10%;
      margin: 2rem 0 0 0;
      max-width: unset;
    }

    .will-fade {
      transition: opacity 0.2s ease-in-out;

      &.faded-out {
        opacity: 0;
      }

      &.faded-in {
        opacity: 1;
        transition-delay: 0.3s;
      }
    }


    .form-sent-message {
      z-index: 1;
      position: absolute;
      width: 250px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% + 70px));
      transition: all 0.3s ease-out;

      p {
        color: white;
        font-size: 18px;
        text-align: center;
      }

      &.faded-in {
        transform: translate(-50%, -50%);
      }
    }

    .form-label {
      font-size: 11px;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;

      @media (max-width: 900px) {
        display: none;
      }
    }

    .form-controls-container {
      position: relative;
      z-index: 1;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 900px) {
        flex-direction: column;
      }

      .form-control {
        $input-inactive-border-color: #565656;
        $input-active-border-color: #616161;
        width: calc(50% - 20px);
        padding-right: 20px;
        margin-bottom: 15px;

        align-self: flex-end;

        @media (max-width: 900px) {
          width: 80%;
          margin: 0 auto 15px auto;
          padding-right: 0;
        }

        &:last-child {
          @media (min-width: 901px) {
            width: calc(100% - 40px);
            margin: 0 0 0 0;
          }
        }

        label {
          color: white;
          font-size: 12px;
          line-height: 16px;
          display: inline-block;
        }

        &-input {
          width: 100%;
          border: 1px solid white;
          border-radius: 4px;
          padding: 10px;
          background-color: transparent;

          color: white;

          caret-color: #F1C414;

          &.text,
          &.dropdown {
            height: 35px;
          }

          &::placeholder {
            color: #E3E4E3;
            font-size: 8px;
          }

          &::-webkit-input-placeholder { /* Edge */
            color: #E3E4E3;
            font-size: 8px;
          }
          
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #E3E4E3;
            font-size: 8px;
          }
          
        }

        select {
          color: white;
          font-family: "Sinkin Sans", sans-serif;
          font-weight: 200;
          font-size: 10px;
          option {
            color: black;
          }
        }

        textarea {
          resize: none;
          height: 100px;
        }
      }
    }

    .submit-button {
      position: relative;
      z-index: 1;
      border: none;
      cursor: pointer;
      font-weight: bold;
      font-size: 0.9em;
      text-decoration: none;
      background-color: transparent;
      padding: 1px;
      border: none;
      display: flex;
      color: white;
      width: calc(100% - 60px);

      margin: 7px 0 0 0;

      align-items: center;
      justify-content: flex-end;

      font-size: 14px;
      line-height: 37px;

      @media (max-width: 900px) {
        justify-content: flex-start;
        font-size: 12px;
        line-height: 32px;
        width: 80%;
        margin: 0 10%;
      }

      svg {
        margin: 0 0 0 10%;
      }

      &:focus {
      }
    }
  }
}