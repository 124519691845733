.social-title {
	padding-top: 10px;
}

.social-slider {
	padding-top: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.icon-wrapper {
		width: 85px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		a {
			display: flex;
			.icon {
				height: 18px;
				width: 18px;
			}
		}
	}
}
