// SINKIN-SANS

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-100ThinItalic.otf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-100Thin.otf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-200XLight.otf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-200XLightItalic.otf');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-300Light.otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-300LightItalic.otf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-400Italic.otf');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-400Regular.otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-500Medium.otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-500MediumItalic.otf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-600SemiBold.otf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-600SemiBoldItalic.otf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-700Bold.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-700BoldItalic.otf');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-800Black.otf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-800BlackItalic.otf');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-900XBlack.otf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sinkin Sans';
    src: url('../../assets/fonts/SINKIN-SANS/SinkinSans-900XBlackItalic.otf');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

// ROBOTO

@font-face {
    font-family: 'Roboto-Black';
    src: url('../../assets/fonts/ROBOTO/Roboto-Black.ttf');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Black';
    src: url('../../assets/fonts/ROBOTO/Roboto-BlackItalic.ttf');
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../assets/fonts/ROBOTO/Roboto-Bold.ttf');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../assets/fonts/ROBOTO/Roboto-BoldItalic.ttf');
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../assets/fonts/ROBOTO/RobotoCondensed-Bold.ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../assets/fonts/ROBOTO/RobotoCondensed-BoldItalic.ttf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../assets/fonts/ROBOTO/RobotoCondensed-Italic.ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../assets/fonts/ROBOTO/RobotoCondensed-Light.ttf');
    font-weight: lighter;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../assets/fonts/ROBOTO/RobotoCondensed-LightItalic.ttf');
    font-weight: lighter;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../assets/fonts/ROBOTO/RobotoCondensed-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/ROBOTO/Roboto-Italic.ttf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/ROBOTO/Roboto-Light.ttf');
    font-weight: lighter;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/ROBOTO/Roboto-Medium.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/ROBOTO/Roboto-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/ROBOTO/Roboto-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/ROBOTO/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/ROBOTO/Roboto-Thin.ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/ROBOTO/Roboto-ThinItalic.ttf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}