.success-together {
  padding: 12vw;
  padding-bottom: 5vw;
  display: flex;

  position: relative;

  .background-image-container {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 246%;
    width: 100%;
    z-index: -1;
    background-position: bottom -356px center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 980px) {
      background-position: bottom -94px center;
    }

    @media (min-width: 1300px) {
      background-position: bottom -290px center;
    }

    @media (min-width: 1600px) {
      background-position: bottom -200px center;
    }
    @media (min-width: 2000px) {
      background-position: bottom -500px center;
    }
  }

  .title-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      color: #fff;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 26px;
      margin-top: 60px;
    }

    .subtitle {
      color: #fff;
      font-size: 40px;
      line-height: 59px;
    }
  }

  .link-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
    padding-left: 10%;

    .link {
      text-decoration: none;
      width: 80%;
      color: #fff;
      font-size: 14px;
      line-height: 37px;
      text-transform: uppercase;
      width: 100%;
      text-align: right;

      svg {
        margin-top: 14px;
        margin-left: 24px;
      }
    }
  }

  @media (max-width: 1300px) {
    flex-flow: column;

    .title-container {
      width: 100%;
      .title {
        margin-bottom: 20px;
      }

      .subtitle {
        margin-bottom: 20px;
      }
    }

    .link-container {
      padding: 0;

      .link {
        width: auto;
        text-align: left;
        svg {
          margin-left: 20px;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 5vw;
    padding-bottom: 7vw;

    .background-image-container {
      background-size: cover;
    }

    .title-container {
      .title {
        font-size: 14px;
        margin-bottom: 10px;
      }

      .subtitle {
        margin-bottom: 10px;
        font-size: 24px;
      }
    }

    .link-container {
      width: 100%;
      margin-bottom: 50px;

      .link {
        font-size: 12px;
        width: 100%;
      }
    }
  }
}
