.information-sectionb {
	position: relative;
	width: 100%;
	background-color: transparent;

	$background-height-mobile: 500px;


	.gradient-container {
		position: absolute;
		top: 100%;
		width: 100%;
		height: 200px;
		z-index: 1;
		
		&.mareah {
			background-image: linear-gradient(to bottom, #BFDDE0 1%, #BFDDE0 24%, transparent 100%);
			// background-color: #BFDDE0;
			height: 700px;
			top: 99%;
		}

		&.kalea {
			background-image: linear-gradient(to bottom, rgba(#F1C400, 0.27) 0%, rgba(#F1C400, 0.13) 63%, transparent 100%);
			// background-color: #F1C400;
		}
	}

	.info-container {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-evenly;

		&.inverted {
			flex-direction: row;

		}

		&.kalea {
			background-image: linear-gradient(to bottom, #F1C400 82%, rgba(#F1C400, 0.27) 100%);
		}

		&.grupo-guaparo {
			background-color: #D3D6DE;

			.background-container {
				@media (min-width: 641px) {
					top: -36px;
				}
			}
		}
		&.grupo-gabanes {
			z-index: 2;
			.background-container {
				//posible cambio
				@media (min-width: 641px) {
					//top: -36px;
				}

			}
		}
		&.rafay {
			z-index: 2;
			.background-container {
				//posible cambio
				@media (min-width: 641px) {
					top: -36px;
				}

			}
		}

		.background-container {
			position: absolute;
			top: -100px;
			height: 108%;
			width: 50%;
			background-size: cover;
			background-repeat: no-repeat;
			z-index: 9;
			max-width: 655px;//690px
			right: 0;
			object-fit: contain;
			object-position: left;

			&.inverted {
				object-position: right;
			}
	
			@media (max-width: 640px) {
				top: 64%;
				height: $background-height-mobile;
				width: 100%;
				max-width: 1000px;
				height: auto;
			}
	
			@media (max-width: 500px) {
				top: 66%;
			}
	
			@media (max-width: 390px) {
				top: 68%;
			}
	
			@media (max-width: 365px) {
				top: 73%;
			}
	
			@media (max-width: 511px) {
				width: 100%;
				max-width: 1000px;
				height: auto;
			}
	
			@media (max-width: 500px) {
				top: 66%;
			}
	
			@media (max-width: 390px) {
				top: 68%;
			}
	
			@media (max-width: 365px) {
				top: 73%;
			}
	
			@media (max-width: 511px) {
				width: 100%;
			}
		}

		&.kalea {
			.background-container {
				top: -37px;//-194px
				object-fit: contain;
			}
		}

		.image-container {
			width: 50%;
			
			&.mareah {
				height: 840px;
			}
			&.kalea {
				height: 840px;
			}
			&.grupo-guaparo {
				height: auto;
			}

			&.grupo-gabanes {
				height: auto;
			}
			&.rafay{
				height: auto;
			}

			&.simple {
				// margin-top: 113px;
				z-index: 9999;

				@media (min-width: 1001px) {
					display: flex;
					flex-direction: row;
					align-items: center;

					&.inverted {
						flex-direction: row-reverse;
						align-items: center;
					}
				}
			}

			.simple-image {
				max-width: 370px;

				@media (max-width: 1000px) {
					width: 65%;
					max-width: 250px;
				}
			}

			.background-container {
				&.inverted {
					left: unset;
				}

				&.for-business {

				}
			}

			&.inverted {
				text-align: right;
			}
		}

		&.simple {
			padding: 3rem 8rem;
			padding-bottom: calc(3rem + 60px);
		}

		&__title {
			width: 50%;
			max-width: 684px;
			display: flex;
			flex-direction: column;
			
			&.big {
				padding: 100px 128px;
				// padding-bottom: 0;

				&.inverted {
					padding-top: 0;
					padding-bottom: 264px;
					@media (max-width: 679px) {
						padding-bottom: 50px;
					}
				}

				
				@media (max-width: 1000px) {
					padding-top: 0;
				}

			}

			&.simple {
				// margin-top: 113px;

				@media (max-width: 1000px) {
					margin-top: 0;
					margin-bottom: 80px;
				}

				z-index: 9999;

				@media (min-width: 1001px) {
					max-width: 444px;
				}
			}

			.title {
				font-size: 24px;
				line-height: 32px;
				margin-bottom: 40px;
				color: #3D3935;
			}
	
			.description {
				margin-bottom: 25px;
				font-family: 'Roboto';
				font-size: 16px;
				line-height: 22px;
				white-space: pre-line;
			}

			.bullets {
				list-style: none;
				color: #3D3935;
				padding-left: 20px;
				font-weight: 500;
				max-width: 386px;

				.bullet {
					font-family: 'Roboto';
					font-size: 16px;
					line-height: 22px;
					position: relative;

					&::before {
						content: '';
						background: #EE2737;
						width: 5px;
						height: 5px;
						position: absolute;
						top: 0.5rem;
						left: -20px;
						border-radius: 50%;
					  }
				}
			}

			.stores {
				.main-text {
					color: #EE2737;
					.number {
						margin-right: 7px;
						font-size: 55px;
					}
				}

				.store-names {
					line-height: 16px;
					font-size: 14px;
					color: #3D3935;
				}
			}

			.brand-link {
				display: flex;
				margin-top: 40px;
				align-items: center;
				span {
					width: 300px;
					text-transform: uppercase;
					font-size: 14px;
					line-height: 37px;
					color: #3D3935;
				}

				svg {}

				&.simple {
					margin-top: 0;
				}
			}

			.brand {
				// display: block;

				&.mareah {
					width: 230px;
					margin-bottom: 50px;
				} 

				&.kalea {
					width: 230px;
				}

				&.grupo-guaparo {
					width: 230px;
					margin-bottom: 50px;
				}
				&.grupo-gabanes {
					width: 326px;//230px
					margin-bottom: 50px;
				}
				&.rafay {
					width: 326px;//230px
					margin-bottom: 50px;
				}

				@media (max-width: 1000px) {
					&.mareah {
						margin: 0 auto;
						margin-bottom: 50px;
						width: 65%;
						max-width: 250px;
					} 
	
					&.kalea {
						margin: 0 auto;
						width: 65%;
						max-width: 250px;
					}
	
					&.grupo-guaparo {
						margin: 0 auto;
						margin-bottom: 50px;
						width: 65%;
						max-width: 250px;
					}
					&.grupo-gabanes {
						margin: 0 auto;
						margin-bottom: 50px;
						width: 65%;
						max-width: 250px;
					}
					&.rafay {
						margin: 0 auto;
						margin-bottom: 50px;
						width: 65%;
						max-width: 250px;
					}
				}
			}
		}
	}

	@media (max-width: 400px) {
		.image-container {

			.simple-image {
				width: 90%;
			}
		}

	}

	@media (min-width: 550px) and (max-width: 999px) {

		.image-container {

			&.inverted {
				margin-bottom: 136px;
			}
			&.simple {
				margin-bottom: 0;
			}


			&.mareah {
				height: 94vh;
				margin-bottom: 58px;
			}
			&.kalea {
				height: 94vh;
				margin-bottom: 58px;
			}
			&.grupo-guaparo {
				height: 94vh;
				margin-bottom: 58px;
			}
			&.grupo-gabanes {
				height: 94vh;
				margin-bottom: 58px;
			}
			&.rafay {
				height: 94vh;
				margin-bottom: 58px;
			}
		}

	}

	@media (max-width: 640px) {
		.image-container {

			&.mareah {
				margin-bottom: 0;
			}
			&.grupo-guaparo {
				margin-bottom: 0;
			}
			&.grupo-gabanes {
				margin-bottom: 0;
			}
			&.rafay {
				margin-bottom: 0;
			}
		}
	}

	@media (max-width: 679px) {
		.info-container, .info-container {

			&__title {
				
				&.big {
					padding: 0px 34px 100px;
					padding-top: 0;
				}
			}

			&.inverted {
				&.kalea {
					.info-container__title.big {
						padding: 100px 34px;
						padding-top: 0;
					}
				}

			}

		}
	}

	@media (min-width: 679px) and (max-width: 999px) {
		.info-container, .info-container {

			&__title {
				
				&.big {
					padding: 0px 34px 100px;
				}
			}

			&.inverted {
				&.kalea {
					.info-container__title.big {
						padding: 100px 34px;
						padding-top: 0;
					}
				}

			}

		}
	}

	@media (max-width: 1000px) {
		.info-container, .info-container {
			flex-direction: column-reverse !important;

			&.inverted {
				flex-direction: column !important;
			}

			&.simple {
				flex-direction: column-reverse !important;
			}

			padding: 0 !important;

			&__title {
				width: 100% !important;

				&.simple {
					padding: 27px;
				}
				
				&.big {
					// padding: 100px 34px;
				}
			}

			.image-container, .image-container {
				position: relative !important;
				width: 100% !important;

				&.simple {
					margin-top: 0;
					z-index: 9;

					text-align: center !important;
					
				}

				&.mareah {
					height: 122vw;
				}
				&.kalea {
					height: 122vw;
				}
				&.grupo-guaparo {
					height: 122vw;
				}
				&.grupo-gabanes {
					height: 122vw;
				}
				&.rafay {
					height: 122vw;
				}

				.background-container, .background-container {
					width: 100% !important;

					&.for-business {
						top: -4% !important;
					}
				}
			}
		}
	}


}
