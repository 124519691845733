.achivements {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  margin-top: -5px;

  background: linear-gradient(#8bb8be 75%, rgb(243, 195, 2) 75% 100%);
  // padding-top: 200px;
  padding-bottom: 200px;

  .gradient-container {
    position: absolute;
    display: none;
    // border: 3px solid red;
    bottom: 100px;
    width: 100%;
    height: 200px;
    z-index: 1;
    background-image: linear-gradient(to top, #f1c400, transparent);

    @media (min-width: 500px) and (max-width:900px) {
      display: block;
    }
  }

  img {
    z-index: 0;
    position: absolute;
  }

  img.achievements-desktop-pyramid {
    width: 100%;
    height: 100%;
    // object-fit: cover;
    object-position: top;
    margin-top: -200px;
    top: 121px;
    right: 143px;
  }

  img.achievements-mobile-pyramid {
    display: none;
  }

  @media (max-width: 1300px) {
  }

  @media (max-width: 900px) {
    img.achievements-desktop-pyramid {
      display: none;
    }

    img.achievements-mobile-pyramid {
      display: inline;
      width: 100%;
      margin-top: 0;
      max-height: 1440px;
    }
  }

  @media (max-width: 700px) {
    background: linear-gradient(#8bb8be 45%, rgb(243, 195, 2) 45% 100%);
    img.achievements-mobile-pyramid {
      display: inline;
      width: 100%;
      // margin-top: -100px;
    }
  }

  .text {
    width: 80%;
    /* margin: 98px 0 0 11%; */
    margin: 0 auto;
    margin-top: 98px;
    margin-bottom: 24px;
    z-index: 1;
    @media (max-width: 900px) {
      // width: 90%;
      margin: 90px 0 40px 10%;
    }
    h2 {
      color: #3d3935;
      text-align: left;
      font-family: "Sinkin Sans", sans-serif;
      font-weight: 500;

      font-size: 40px;
      line-height: 60px;
      width: 50%;
      max-width: 500px;

      @media (max-width: 1200px) {
        font-size: 32px;
        line-height: 49px;
      }

      @media (max-width: 900px) {
        font-size: 24px;
        line-height: 38px;
        width: unset;
        font-weight: 600;
      }
    }
  }

  a.conoce {
    display: flex;
    margin-top: 40px;
    align-items: center;
    z-index: 1;
    width: 80%;
    margin: 0 10%;
    span {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 37px;
      color: #3D3935;
      margin-right: 25px;
    }

    @media (max-width: 900px) {
      display: inline;
      width: 90%;
      margin: 0 0 0 10%;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
    }
  }
}
.cards-maina {
  display: flex;
  gap: 5%;
  flex-direction: row;

  width: 80%;
  margin: 0 10%;
  margin-bottom: 24px;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  .card {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 0px;
    position: relative;
    color: black;
    // margin-bottom: 3rem;
    // margin-top: 3rem;
    // @media (max-width: 768px) {
    // 	width: 25rem;
    // }
    pointer-events: none;

    width: 25%;

    @media (max-width: 900px) {
      width: unset;
    }

    .card-header {
      display: flex;
      flex-direction: column;
      @media (max-width: 900px) {
        width: 90%;
        // margin: 0 0 0 10%;
      }
      h2.title {
        font-family: "Sinkin Sans", sans-serif;
        font-weight: 700;
        color: #3d3935;
        font-size: 12px;
        line-height: 20px;
        @media (max-width: 900px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .amount {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 6px;
        margin-top: 10px;
        h1.title {
          font-family: "Sinkin Sans", sans-serif;
          font-weight: 200;
          color: #ee2737;
          font-size: 70px;
          line-height: 65px;

          @media (max-width: 1200px) {
            font-size: 60px;
            line-height: 65px;
          }
          @media (max-width: 900px) {
            font-size: 50px;
            line-height: 66px;
            margin-bottom: 25px;
          }
        }
        h2.title {
          font-family: "Sinkin Sans", sans-serif;
          font-weight: 600;
          color: #ee2737;
          font-size: 18px;
          @media (max-width: 900px) {
            font-size: 20px;
            line-height: 66px;
          }
        }
      }
    }

    .description {
      margin-top: 0;
      visibility: hidden;
      opacity: 0;
      max-height: 0;
      transition: 1s all linear;
      // position: absolute;

      @media (max-width: 768px) {
        font-size: 0.9rem;
        visibility: visible;
        opacity: 1;
        margin-top: 2rem;
        max-height: 30rem;
      }
    }
  }
}
