.culture-we-represent {

    $background-height-mobile: 500px;
    
    padding: 5vw;
    background-color: #00b0b9;
    position: relative;
    @media (max-width: 640px) {
        padding-bottom: 300px;
    }

    .background-container {
        position: absolute;
        right: 0;
        top: -4%;
        height: 108%;
        width: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 9;
        max-width: 690px;

        @media (min-width: 641px) and (max-width: 980px) {
            object-fit: contain;
        }

        @media (max-width: 640px) {
            top: 64%;
            height: $background-height-mobile;
            width: 100%;
            max-width: 1000px;
            height: auto;
        }

        @media (max-width: 500px) {
            top: 66%;
        }

        @media (max-width: 390px) {
            top: 68%;
        }

        @media (max-width: 365px) {
            top: 73%;
        }

        @media (max-width: 511px) {
            width: 100%;
        }
    }

    .content-container {

        width: 50%;
        padding-right: 5%;
        padding-left: 7%;

        .title {
            font-size: 40px;
            color: #fff;
            line-height: 59px;

            @media (max-width: 640px) {
                font-weight: 500;
                margin-top: 20px;
            }
        }
    
        .subtitle {
            font-size: 24px;
            line-height: 35px;
            margin-top: 25px;
            color: #fff;
        }
    
        .cards-container {
    
            margin-top: 27px;
    
            .card {
                color: #fff;
                margin-bottom: 11px;
                font-size: 21px;
                font-family: 'Roboto';
            }
        }

        @media (max-width: 1199px) {
            .title {
                font-size: 24px;
                line-height: 34px;
            }
            .subtitle {
                font-size: 16px;
            }
            .cards-container {
        
                .card {
                    font-size: 16px;
                }
            }
        }

        @media (max-width: 640px) {
            width: 100%;
            padding: 0 8px;
        }
    }

}