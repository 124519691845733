.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding: 2rem 1rem;
  background-color: #f6f6f6;

  @media (max-width: 980px) {
    flex-direction: column;
    height: initial;
  }
  @media (max-width: 600px) {
    padding: 2rem 2rem;
  }
  .carousel {
    width: 90%;
    min-height: 70vh;
    padding: 1rem 1rem;
  }
  .carousel-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 5%;
    text-align: center;
    justify-content: center;
    font-family: "Montserrat";

    @media (max-width: 980px) {
      width: 90%;
    }
    .carousel-title {
      color: #262626;
      font-size: 2.7rem;
    }

    .link {
      color: #0e0d0d;
      font-size: 1.5rem;
      text-decoration: underline;
      margin-top: 5vh;

      p {
        font-weight: 900;
        text-transform: uppercase;
      }
      a:hover {
        cursor: pointer;
        color: #262626;
      }
    }
  }
  .carousel-content {
    width: 40%;
    min-height: 60vh;
    position: relative;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5% 5%;
    color: black;
    font-size: 1.4rem;
    line-height: 1.5;
    font-style: italic;
    font-weight: 300;
  }
  .slick-next {
    right: 25px;
  }
  .slick-prev {
    left: -15px;
    z-index: 1;
  }
  .slick-active {
    .carousel-content {
      background-color: #d8d8d8;
      opacity: 0.5;
    }
  }
  .slick-current {
    .carousel-content {
      background-color: #ffffff;
      opacity: 1;
      -moz-box-shadow: 3px 3px 5px 6px #ccc;
      -webkit-box-shadow: 3px 3px 5px 6px #ccc;
      box-shadow: 3px 3px 5px 6px #ccc;
    }
    .content {
      color: #262626;
    }
  }

  .slick-next::before {
    font-size: 60px;
    color: #6e6e6e;
  }
  .slick-prev::before {
    font-size: 60px;
    color: #9ca4ab;
  }
}
