.investor {
	height: 90vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: white;
}
.investors {
	&__slider {
		position: relative;
		z-index: 9;

		.background-container {
			position: absolute;
			width: 100%;
			left: 0;
			z-index: -1;
			top: 0;
			background-size: 2660px;
			background-repeat: no-repeat;
			background-position: -549px 30%;
			height: 1149px;

			@media (max-width: 1091px) {
				background-position: -1510px -12em;
			}
			@media (min-width: 2050px) {
				background-position: -365px 30%;
			}
			@media (min-width: 2200px) {
				background-position: unset;
				background-size: 160%;
			}

			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				height: 400px;
				width: 100%;
				// border: 5px solid blue;
				// background-image: linear-gradient(to bottom, transparent 20%, rgba(191, 221, 224, 0.6) 50%, #BFDDE0 100%);
			}
		}

		& > .location-slider {
			@media (max-width: 980px) {
				display: none !important;
			}
		}
		& > .social-slider {
			@media (max-width: 980px) {
				right: 1rem;
			}
		}
	}

	&__section {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2rem 2rem;
		z-index: 10;
		background-image: linear-gradient(
			to bottom,
			transparent 10%,
			#bfdde0 23%,
			#bfdde0 100%
		);
		overflow: hidden;

		@media (min-height: 800px) {
			background-position: 0px 34%;
			background-size: 100% 141%;
			background-repeat: no-repeat;
		}

		.hexagon-container {
			// width: 80%;
			// margin: 0 auto;
			height: auto;
			position: relative;
			display: flex;
			justify-content: center;

			img {
				width: 100%;
				max-width: 900px;
				min-width: 826px;
			}

			.hexagon-text {
				$offset: 50px;

				position: absolute;
				max-width: 243px;

				@media (max-width: 970px) {
					max-width: 280px;
				}

				p {
					text-align: center;
				}

				@mixin text-uppercase-shadow {
					color: #fff;
					text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.76);
					text-transform: uppercase;
				}

				&.middle {
					top: 50%;
					left: 50%;
					transform: translate(-50%, calc(-50% + 30px));
					max-width: 35%;
					color: #fff;
					font-family: 'Roboto';

					p {
						font-size: 21px;
						line-height: 26px;
						font-family: 'Roboto';

						@media (max-width: 970px) {
							font-size: 16px;
							line-height: 22px;
						}
					}
				}
				&.left {
					top: 50%;
					left: 0;
					font-size: 15px;
					p {
						line-height: 18px;
					}
					transform: translate($offset, -105px);
					@include text-uppercase-shadow;
					@media (max-width: 970px) {
						display: none;
					}
				}
				&.right {
					top: 50%;
					right: 0;
					font-size: 15px;
					p {
						line-height: 18px;
					}
					transform: translate(-$offset, -105px);
					@include text-uppercase-shadow;
					@media (max-width: 970px) {
						display: none;
					}
				}
				&.top {
					top: 0;
					left: 50%;
					transform: translate(-50%, $offset);
					max-width: unset;
					font-size: 36px;
					color: #fff;

					@media (max-width: 970px) {
						max-width: 350px;
					}

					p {
						line-height: 59px;
						@media (max-width: 970px) {
							font-size: 24px;
							line-height: 34px;
						}
					}
				}
				&.bottom {
					bottom: 0;
					left: 50%;
					font-size: 15px;
					p {
						line-height: 18px;
					}
					transform: translate(-50%, -100px);
					@include text-uppercase-shadow;

					.on-mobile {
						display: none;
					}

					@media (max-width: 970px) {
						font-size: 12px;
						line-height: 24px;
						transform: translate(-50%, -20px);

						p {
							margin: 10px 0;
						}

						.on-mobile {
							display: block;
						}
					}
				}
			}
		}

		&__title {
			width: 40%;

			h1 {
				text-align: center;
				color: #262626;
				line-height: 1.3;
				font-size: 2.5rem;
			}
		}

		&__content {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-evenly;
			padding: 2rem 2rem;
			&__left {
				width: 50%;
				height: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;

				&__item {
					width: 50%;
					margin-top: 2%;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 5%;
					background-color: #ededed;
					-webkit-box-shadow: 1px 1px 6px 1px rgba(184, 184, 184, 0.76);
					-moz-box-shadow: 1px 1px 6px 1px rgba(184, 184, 184, 0.76);
					box-shadow: 1px 1px 6px 1px rgba(184, 184, 184, 0.76);

					p {
						text-transform: uppercase;
						text-align: center;
						font-weight: bold;
						line-height: 1.3;
						color: #262626;
					}

					@media (min-width: 48rem) {
						width: 40%;
						&:nth-child(odd) {
							margin: 5%;
						}
					}

					@media (min-width: 62rem) {
						width: 40%;
						&:not(:nth-child(4n)) {
							margin: 5%;
						}
					}

					&:before {
						content: '';
						float: left;
						padding-top: 100%;
					}
				}
			}

			&__right {
				padding: 2rem 2rem;
				width: 50%;
				height: 100%;

				h2 {
					color: #262626;
					line-height: 1.5;
					font-weight: 300;
					white-space: pre-line;
				}
			}
		}
	}
	&__section2 {
		.background-container-section {
			position: absolute;
			top: -1px;
			left: 0;
			// height: 239px;
			// width: 100%;
			// background-image: linear-gradient(to bottom, #BFDDDF 24%, transparent 100%);

			height: 363px;
			width: 100%;
			background-image: linear-gradient(
				to bottom,
				#bfdddf 4%,
				rgba(191, 221, 223, 0.4) 47%,
				transparent 87%,
				transparent 100%
			);
			z-index: -1;

			@media (max-width: 450px) {
				height: 800px;
			}
		}

		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		// width: 90%;
		padding: 0 10%;
		margin: 0 auto;
		height: 100%;
		// padding: 20px 7vw;

		z-index: 11;
		background-color: transparent;
		// padding-top: 166px;
		padding-top: 100px;
		padding-bottom: 480px;
		background-position: top 400px center;
		background-repeat: no-repeat;
		background-size: cover;

		@media (max-width: 400px) {
			background-size: 1000px;
			background-position: top 758px center;
		}

		.main-section {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 42%;
			min-height: 200px;

			@media (max-width: 980px) {
				width: 50%;
			}

			.title {
				color: #3d3935;
				font-size: 32px;
				line-height: 59px;
				margin-bottom: 34px;

				@media (max-width: 600px) {
					font-weight: 700;
					font-size: 24px;
					line-height: 44px;
				}
			}

			.description {
				font-family: 'Roboto';
				font-size: 20px;
				line-height: 34px;
				color: #3d3935;

				@media (max-width: 600px) {
				}
			}
		}

		.lines-section {
			display: flex;
			flex-direction: column;
			flex-flow: column;
			width: 42%;
			min-height: 200px;
			justify-content: flex-end;
			padding-left: 30px;

			@media (max-width: 980px) {
				width: 50%;
			}

			.line {
				font-size: 14px;
				line-height: 24px;
				margin-top: 20px;
			}
		}

		@media (max-width: 900px) {
			flex-direction: column;
			background-position-x: 49%;

			.main-section {
				width: 100%;
				margin-bottom: 20px;
			}

			.lines-section {
				width: 100%;
				padding-left: 0;
				min-height: unset;
			}
		}

		@media (min-width: 401px) and (max-width: 900px) {
			background-position: top 583px center;
		}

		@media (max-width: 400px) {
			background-position: top 660px center;
		}

		// &__grid {
		//   display: flex;
		//   flex-wrap: wrap;
		//   &__item {
		//     width: 20%;
		//     margin: 2%;
		//     display: flex;
		//     align-items: center;
		//     justify-content: center;
		//     padding: 1%;
		//     background-color: #ffffff;
		//     -webkit-box-shadow: 1px 1px 6px 1px rgba(184, 184, 184, 0.76);
		//     -moz-box-shadow: 1px 1px 6px 1px rgba(184, 184, 184, 0.76);
		//     box-shadow: 1px 1px 6px 1px rgba(184, 184, 184, 0.76);

		//     p {
		//       font-style: italic;
		//       text-align: center;
		//       font-size: 1.3rem;
		//       line-height: 1.3;
		//       color: #262626;
		//     }

		//     @media (min-width: 48rem) {
		//       width: 20%;
		//       &:nth-child(odd) {
		//         margin: 2%;
		//       }
		//     }

		//     @media (min-width: 62rem) {
		//       width: 20%;
		//       &:not(:nth-child(4n)) {
		//         margin: 2%;
		//       }
		//     }

		//     &:before {
		//       content: "";
		//       float: left;
		//       padding-top: 100%;
		//     }
		//   }
		// }

		// &__title2 {
		//   width: 100%;
		//   display: flex;
		//   align-items: center;
		//   justify-content: center;
		//   flex-direction: column;
		//   &__text {
		//     width: 50%;
		//     h1 {
		//       text-align: center;
		//       font-family: "Montserrat";
		//       color: #262626;
		//       line-height: 1.3;
		//       font-size: 2.5rem;
		//     }
		//     h2 {
		//       text-align: center;
		//       font-family: "Montserrat";
		//       color: #262626;
		//       line-height: 1.3;
		//       margin-top: 2rem;
		//       margin-bottom: 2rem;
		//     }
		//   }
		// }
	}
}
