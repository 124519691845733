@import 'fonts-def';

.font-sinkin {

    font-family: 'Sinkin Sans';
    font-weight: 400;
    font-style: normal;

    @mixin set-weight($weight) {
        font-family: 'Sinkin Sans';
        font-weight: $weight;
    }

    &-100 { @include set-weight(100) }
    &-200 { @include set-weight(200) }
    &-300 { @include set-weight(300) }
    &-400 { @include set-weight(400) }
    &-500 { @include set-weight(500) }
    &-600 { @include set-weight(600) }
    &-700 { @include set-weight(700) }
    &-800 { @include set-weight(800) }
    &-900 { @include set-weight(900) }
}