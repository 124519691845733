.simplest-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 60px;
    -webkit-box-shadow: 1px 3px 7px 1px rgba(0,0,0,0.26);
    -moz-box-shadow: 1px 3px 7px 1px rgba(0,0,0,0.26);
    box-shadow: 1px 3px 7px 1px rgba(0,0,0,0.26);


    .card-text {
        text-align: center;
    }
}