.information-section {
  position: relative;
  width: 100%;

  background: url(../../assets/icons/home/BACKGROUNDS/information-section-background.webp),
    linear-gradient(to bottom, #b4dfe5, white);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 -58px;

  @media (max-width: 900px) {
    background-size: 121%;
  }

  .info-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    padding: 15rem 3rem 0 3rem;

    @media (max-width: 900px) {
      flex-direction: column-reverse;
      padding: 67vw 0 0 0;
    }

    &__title {
      width: 50%;
      padding-top: 38px;
      @media (max-width: 900px) {
        width: 80%;
        margin: 4rem 10% 0 10%;
      }

      h1.title {
        font-family: "Sinkin Sans", sans-serif;
        font-weight: 500;
        color: #3d3935;
        font-size: 40px;
        line-height: 60px;
        @media (max-width: 1200px) {
          font-size: 32px;
          line-height: 47px;
        }
        @media (max-width: 900px) {
          font-size: 24px;
          line-height: 34px;
          font-weight: 600;
        }
      }

      h2 {
        font-family: "Sinkin Sans", sans-serif;
        font-weight: 700;
        color: #3d3935;
        font-size: 16px;
        line-height: 32px;
        font-style: normal;
        @media (max-width: 900px) {
          font-size: 14px;
          line-height: 26px;
        }
      }

      p.description {
        font-family: 'Roboto';
        color: #3d3d3d;
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
        @media (max-width: 900px) {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }

    img {
      align-self: center;
      width: 40%;

      @media (max-width: 900px) {
        width: 77%;
        margin-left: 43px;
      }
    }
  }
}
